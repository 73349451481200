<template lang="html">
    <transition name="modal" v-if="show">
        <div class="Modal__Mask">
        <div class="Modal__Wrapper">
            <div class="Modal__Container">
                <div class="Modal__Header">
                  <slot name="header">
                    <h3>{{ title }}</h3>
                  </slot>
                </div>

                <div class="Modal__Content">
                    <slot name="content"></slot>
                </div>

                <div class="Modal__Footer">
                    <slot name="footer"></slot>
                </div>
            </div>
        </div>
        </div>
    </transition>
</template>

<script>
export default {
  name: 'Modal',

  props: {
    show: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ""
    },
  },
}
</script>

<style lang="scss">
.Modal {
  &__Mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(#000, .6);
    backdrop-filter: blur(.3rem);
    display: table;
    transition: opacity .3s ease;
  }

  &__Wrapper {
    display: table-cell;
    vertical-align: middle;
  }

  &__Container {
    max-width: 600px;
    width: 100%;
    height: 100%;
    max-height: 500px;
    margin: 0px auto;
    padding: 2rem;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    transition: all .3s ease;
    border-width: 8px;
    border-style: solid;
    border-image: linear-gradient(90deg, #FF1564 0%, #FD7328 100%) 1;
    border-right: none;
    border-bottom: none;
    border-left: none;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  &__Header h3 {
    margin-top: 0;
    font-size: 1.5rem;
    font-family: 'Bariol','Open Sans','Arial',sans-serif;
  }

  &__Content {
    margin: 20px 0;
    width: 80%;
    ul {
      list-style: none;
      display: flex;
      flex-direction: column;
      gap: .5rem;
      width: 100%;
      li {
        padding: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        border-radius: 2px;
        background-color: #fff;
        box-shadow: .1rem .1rem 0rem rgba(#000,.07);
        border: 1px solid rgba(#000,0.1);
      }
    }
  }
  &__Footer {
    button {
      padding: 1rem 2rem;
      &:hover {
        cursor: pointer;
      }
    }
  }
  @media only screen and (max-width: 600px) {
    &__Container{
      height: fit-content;
    }
    &__Content{
      width: 100%;
      margin: 0;
      ul{
        padding: 0;
      }
    }
  }
}
</style>
